import { format, formatWithOptions } from 'date-fns/fp'
import {
  addHours,
  subHours,
  isBefore,
  isAfter,
  isWithinInterval,
  subDays
} from 'date-fns'

import { es } from 'date-fns/locale'
import { ddFormat } from './timeTools'

export function isValidDate(dateStr) {
  return !isNaN(new Date(dateStr).getDate())
}

export function shortFormattedDate(dateStr) {
  const shortFormat = formatWithOptions({ locale: es }, 'eee, d MMMM')
  return shortFormat(new Date(dateStr))
}

export function longFormattedDate(dateStr) {
  const longFormat = formatWithOptions({ locale: es }, 'eee, d MMMM yyyy')
  return longFormat(new Date(dateStr))
}

export function formattedDateTime(dateStr) {
  const longFormat = formatWithOptions(
    { locale: es },
    'eee, d.MMM.yyyy hh:mm aa'
  )
  return longFormat(new Date(dateStr))
}

export function formattedIsoDateTime(dateStr) {
  const isoFormat = format('yyyy-MM-dd HH:mm')
  return isoFormat(new Date(dateStr))
}

export function suggestedTime() {
  // @todo Replace by x % 15 === 0
  const now = new Date()
  const hh = now.getHours()
  const mm = now.getMinutes()
  const q = mm / 15
  let mmq = 0
  if (q < 1) mmq = 15
  else if (q > 1 && q < 2) mmq = 30
  else if (q > 2 && q < 3) mmq = 45
  const hhq = q > 3 ? hh + 1 : hh
  return `${ddFormat(hhq + 1)}:${ddFormat(mmq)}`
}

export function isScheduleGreaterThanNow(dateStr) {
  return new Date(dateStr).getTime() >= Date.now()
}

export function isEndPeriodBeforeNow(schedule, hh) {
  return isBefore(subHours(new Date(schedule), hh), new Date())
}

export function isStartTimeAfterNow(schedule, hh) {
  return isAfter(addHours(new Date(schedule), hh), new Date())
}

export function isBeforeSub(start, end, hh) {
  return isBefore(new Date(start), subHours(new Date(end), hh))
}

export function isBeforeAdd(start, end, hh) {
  return isBefore(new Date(start), addHours(new Date(end), hh))
}

export function isBeforeNow(schedule, hh) {
  return isBefore(new Date(), subHours(new Date(schedule), hh))
}

export function isInGracePeriod(effective, schedule, minHH, maxHH) {
  const scheduleDate = new Date(schedule)
  return isWithinInterval(new Date(effective), {
    start: subHours(scheduleDate, minHH),
    end: addHours(scheduleDate, maxHH)
  })
}

export const formattedDate = formatWithOptions({ locale: es }, 'D MMMM YYYY')

export function currentPeriodAsId() {
  return dateAsId(new Date())
}

export function schedulePeriodAsId(schedule) {
  return dateAsId(new Date(schedule))
}

export function dateAsId(date) {
  const formatted = format('yyyyMM')
  const prefix = formatted(date)
  return `${prefix}${weekRange(date)}`
}

export function previousPeriodAsId() {
  const previous = subDays(new Date(), 1)
  const formatted = format('yyyyMM')
  const prefix = formatted(previous)
  return `${prefix}${weekRange(previous)}`
}

export function weekRange(date) {
  const formatted = format('dd')
  const dd = formatted(date)
  const day = Number(dd)
  let w
  if (day <= 7) w = 1
  else if (day <= 14) w = 2
  else if (day <= 21) w = 3
  else w = 4
  return w
}
