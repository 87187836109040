import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'
import Login from '@/views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    alias: '/',
    name: 'Login',
    component: Login,
    meta: { guest: true }
  },
  {
    path: '/loading',
    name: 'Loading',
    component: () =>
      import(/* webpackChunkName: "code" */ '../views/Loading.vue'),
    meta: { guest: true }
  },
  {
    path: '/onverification',
    name: 'OnVerification',
    component: () =>
      import(/* webpackChunkName: "code" */ '../views/OnVerification.vue'),
    meta: { guest: true }
  },
  {
    path: '/provider',
    name: 'Provider',
    component: () =>
      import(/* webpackChunkName: "code" */ '../views/Provider.vue'),
    meta: { guest: true }
  },
  {
    path: '/check/:id',
    name: 'PassCode',
    component: () =>
      import(/* webpackChunkName: "code" */ '../views/PassCode.vue'),
    meta: { auth: true, group: 'party' }
  },
  {
    path: '/requests',
    name: 'Requests',
    component: () =>
      import(/* webpackChunkName: "requests" */ '../views/Requests.vue'),
    meta: { auth: true, group: 'party' }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: { auth: true, group: 'employee' }
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () =>
      import(/* webpackChunkName: "documents" */ '../views/Documents.vue'),
    meta: { auth: true, group: 'party' }
  },
  {
    path: '/request',
    name: 'Request',
    component: () =>
      import(/* webpackChunkName: "request" */ '../views/Request.vue'),
    meta: { auth: true, group: 'party' }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: Login,
    meta: { guest: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function validPath(name) {
  return (
    ['Request', 'Requests', 'Profile', 'Documents', 'PassCode'].find(
      valid => valid === name
    ) !== undefined
  )
}

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(
    async authUser => {
      console.log('authUser', authUser)
      if (authUser && firebase.auth().currentUser) {
        // Is logged in
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(function({ claims }) {
            const isAppInit = router.app.$store.getters['user/isAppInit']
            const areRinit = !!claims.r
            const isG = areRinit
              ? claims.r.find(r => r === 'g') !== undefined
              : false
            if (!isAppInit && areRinit && !isG) {
              if (to.path !== '/loading') {
                return next({
                  path: '/loading'
                })
              }
            } else if (isG) {
              if (to.name !== 'PassCode') {
                if (to.params.id) {
                  return next({
                    path: `/check/${to.params.id}`
                  })
                } else {
                  return next(false)
                }
              }
            } else {
              const areSectionsCompleted =
                (claims.s && claims.s['a'] && claims.s['b']) ||
                router.app.$store.getters['user/areSectionsRequiredCompleted']
              const isSectionCompleted =
                router.app.$store.getters['user/isSectionCompleted']
              const isProfileCompleted = isSectionCompleted('profile')
              const areDocsCompleted = isSectionCompleted('documents')
              const dispatch = router.app.$store.dispatch
              const enableRoute = 'routes/enableRoute'
              if (isProfileCompleted) {
                dispatch(enableRoute, 'Profile')
                dispatch(enableRoute, 'Documents')
              }
              if (areSectionsCompleted) {
                dispatch(enableRoute, 'Requests')
              }
              if (!isProfileCompleted) {
                console.log('canAccess && !isProfileCompleted', to.path)
                if (to.path !== '/profile') {
                  return next({
                    path: '/profile'
                  })
                }
              } else if (!areDocsCompleted) {
                console.log('canAccess && !areDocsCompleted', to.path)
                if (to.path !== '/documents') {
                  return next({
                    path: '/documents'
                  })
                }
              } else {
                next()
              }
            }
          })
      } else {
        // Is not logged in
        if (to.matched.some(record => record.meta.auth) || !validPath) {
          // If try to go to a private route then send it to login
          next({
            path: '/login',
            query: {
              redirect: to.fullPath
            }
          })
        } else {
          next()
        }
      }
    },
    error => {
      console.log('routing', error)
    }
  )
  next()
})

export default router
