import firebase from 'firebase/app'
import 'firebase/firestore'
import { getControlPath } from './apiControl'
import { formatted } from '@/micro/time/timeTools'
import { resolvedNextStageIdById } from '@/workflows/readConditions'

/**
 *
 * @param {String} uid
 * @param {Object} input
 * @param {Object} party
 * @param {Vuex Getter} nextStageIdById
 * @returns
 */
export async function addNewAccessRequest(uid, input, party, rootGetters) {
  const { l, o } = party
  const path = getControlPath(l, o, 'any', 'covid19pass', 'active')
  const resource = composedResource(input)
  const request = composedAccessRequest(uid, party, resource)
  const batch = firebase.firestore().batch()
  try {
    const reqId = await saveNewAccessRequestDoc(path, request, batch)
    await saveInitStageDoc(path, reqId, request, rootGetters, batch)
    return await batch.commit()
  } catch (error) {
    console.log('can not add the request and its stage', error)
    throw error
  }
}

/**
 * Stores new request
 */
async function saveNewAccessRequestDoc(path, request, batch) {
  const docRef = firebase
    .firestore()
    .collection(path)
    .doc()
  const data = accessRequestDoc(request, docRef.id)
  const srcHost = `${window.location.protocol}//${window.location.host}`
  await batch.set(docRef, { ...data, srcHost })
  return docRef.id
}

/**
 * Compose the request doc
 */
function accessRequestDoc(request, docId) {
  return {
    id: docId,
    created_at: firebase.firestore.FieldValue.serverTimestamp(),
    ...request
  }
}

/**
 * Add the initial stage doc
 */
async function saveInitStageDoc(path, reqId, request, rootGetters, batch) {
  const stage = firebase
    .firestore()
    .doc(`${path}/${reqId}`)
    .collection('workflow')
    .doc()

  const data = await initialStageRecord(request, rootGetters)
  return await batch.set(stage, { ...data })
}

/**
 * Prepares the initial request's stage
 */
async function initialStageRecord(request, rootGetters) {
  const nextId = await resolvedNextStageIdById('init', request, rootGetters)
  return {
    uid: request.uid,
    id: 'init',
    type: 'init',
    position: 0,
    role: 'requestor',
    status: 'approved',
    next: nextId,
    effective: firebase.firestore.Timestamp.fromDate(new Date())
  }
}

/**
 * Prepares the initial request
 */
export function composedAccessRequest(uid, party, resource) {
  const code = 'o' || 'c'
  return {
    topic: 'access_request',
    uid,
    ...{ party },
    ...{ resource },
    workflow_id: party.l === 'a' ? `e${code}a` : `p${code}a`
  }
}

/**
 * Prepares the request's resource
 */
export function composedResource(input) {
  return {
    schedule: input.date,
    duration: formatted('hh:mm', input.duration, { hhZero: '00' }),
    time: `${input.fromTime} - ${input.toTime}`,
    schedule_start: `${input.date} ${input.fromTime}`,
    schedule_end: `${input.date} ${input.toTime}`,
    site: input.site,
    terms: input.terms,
    motivation: input.motivation
  }
}
