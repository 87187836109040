import {
  graceDurationToCheckin,
  graceDurationToCheckout,
  graceDurationToCheckoutJustAfterCheckin
} from '@/cfg'
import { isAfter, isBefore, addHours, subHours } from 'date-fns'

// Checkin
export const checkinAheadSchedule = ({ request }) => {
  // now(9:00) < schedule_start - graceHH
  return isBefore(
    new Date(),
    subHours(
      new Date(request.resource.schedule_start),
      graceDurationToCheckin()
    )
  )
}
export const checkinOnSchedule = ({ request }) => {
  const now = new Date()
  const end = new Date(request.resource.schedule_start)
  const graceHH = graceDurationToCheckin()
  // now > schedule_start - graceHH
  const minPeriod = isAfter(now, subHours(end, graceHH))
  // now < schedule_start + graceHH
  const maxPeriod = isBefore(now, addHours(end, graceHH))
  return minPeriod && maxPeriod
}
export const checkinBehindSchedule = ({ request }) => {
  // now(9:00) > schedule_start + graceHH
  return isAfter(
    new Date(),
    addHours(
      new Date(request.resource.schedule_start),
      graceDurationToCheckin()
    )
  )
}

// Checkout
export const checkoutAheadSchedule = ({ request }) => {
  // now(9:00) < schedule_end - graceHH
  return isBefore(
    new Date(),
    subHours(new Date(request.resource.schedule_end), graceDurationToCheckout())
  )
}
export const checkoutOnSchedule = ({ request }) => {
  const now = new Date()
  const end = new Date(request.resource.schedule_end)
  const graceHH = graceDurationToCheckout()
  // now > schedule_end - graceHH
  const minPeriod = isAfter(now, subHours(end, graceHH))
  // now < schedule_end + graceHH
  const maxPeriod = isBefore(now, addHours(end, graceHH))
  return minPeriod && maxPeriod
}
export const checkoutBehindSchedule = ({ request }) => {
  // now(9:00) > schedule_end + graceHH
  return isAfter(
    new Date(),
    addHours(new Date(request.resource.schedule_end), graceDurationToCheckout())
  )
}
export const checkoutException = ({ request }) => {
  // now(9:00) < schedule_start(9:00) + graceHH(10m)
  return (
    checkoutAheadSchedule({ request }) &&
    isBefore(
      new Date(),
      addHours(
        new Date(request.resource.schedule_start),
        graceDurationToCheckoutJustAfterCheckin()
      )
    )
  )
}
