import { conditionAssertion } from './conditions'

export async function resolvedNextStageIdById(stageId, request, rootGetters) {
  const stageDescn = rootGetters['workflows/stageDescriptionById'](
    stageId,
    request
  )
  const context = { stage: stageDescn, request, rootGetters }
  let nextIds = []
  if (stageDescn.alts && stageDescn.alts.length > 0) {
    const evaluations = await Promise.all(
      stageDescn.alts.map(async alt => {
        if (!alt.conditions || alt.conditions.length === 0) {
          return { assertion: false, nextId: alt.id }
        }
        const assertions = alt.conditions.map(cond =>
          conditionAssertion(cond, context)
        )
        const resolved = await Promise.all(assertions)
        const matched = resolved.reduce((p, c) => p && c, true)
        return { matched, nextId: alt.id }
      })
    )
    nextIds = evaluations.filter(ev => ev.matched).map(ev => ev.nextId)
  }
  return nextIds.length > 0
    ? nextIds[0]
    : rootGetters['workflows/outlinedNextStageIdById'](stageId, request)
}
