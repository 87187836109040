import { storageName } from './storageName'

// export function setJsonUserItem(name, jsonObj) {
//   setStorageName(jsonObj.email, jsonObj.appId)
//   setItem(name, JSON.stringify(jsonObj))
// }

export function setJsonItem(name, jsonObj) {
  setItem(name, JSON.stringify(jsonObj))
}

export function setItem(name, jsonString) {
  localStorage.setItem(resolved(name), jsonString)
}

export function getJsonItem(name) {
  return JSON.parse(getItem(name))
}

export function getItem(name) {
  return localStorage.getItem(resolved(name))
}

export function removeItem(name) {
  localStorage.removeItem(resolved(name))
}

function resolved(name) {
  if (storageName() !== null) {
    return `${storageName()}-${name}`
  }
}
