import { wasUnderPeriodToAuth, wasUnderPeriodToAuth3 } from './periodsControl'

// Present / In the real workflow
export function isAuthStage(stage) {
  if (stage !== undefined) {
    const prefix = stage.id.substr(0, 4)
    return prefix === 'auth'
  }
  return false
}

export function isAuth3Stage(stage) {
  return stage !== undefined && stage.id === 'auth3'
}

export function isPass1Stage(stage) {
  return stage !== undefined && stage.id === 'pass1'
}

export function isPass2Stage(stage) {
  return stage !== undefined && stage.id === 'pass2'
}

export function isPassStage(stage) {
  if (stage !== undefined) {
    const prefix = stage.id.substr(0, 4)
    return prefix === 'pass'
  }
  return false
}

export function passStageCount(item) {
  return item.workflow.stages.reduce(
    (acum, stage) => (isPassStage(stage) ? acum + 1 : acum),
    0
  )
}

export function stageRoleId(stage) {
  return stage.role
}

// @todo Compare the effective time when the workflow
// returns to a any previous stage
export function currentStageOf(stages) {
  const copy = [...stages]
  copy.sort((a, b) => a.position - b.position)
  return copy.slice(-1)[0]
}

// @todo Compare the effective time when the workflow
// returns to a any previous stage
export function previousStageOfCurrent(stages) {
  const copy = [...stages]
  copy.sort((a, b) => a.position - b.position)
  return copy.slice(-2)[0]
}

// Planned / In the workflow description
export function isInitStage(stage) {
  return stage.id === 'init'
}

export function isCheckinStage(stage) {
  return stage.id === 'checkin'
}

export function isCheckinById(id) {
  return id === 'checkin'
}

export function isNoShowStage(stage) {
  return isCheckinStage(stage) && stage.no_show
}

export function isCanceledStage(stage) {
  return isCheckinStage(stage) && stage.canceled
}

export function isCheckoutStage(stage) {
  return stage.id === 'checkout'
}

export function isUnderExpiration(stage, item) {
  const hasExpiration = item.resource.schedule_end !== undefined
  return hasExpiration
    ? wasUnderPeriodToAuth(stage.effective, item.resource.schedule_end) ||
        wasUnderPeriodToAuth3(stage.effective, item.resource.schedule_end)
    : true
}
