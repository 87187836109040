import { isBeforeNow, isBeforeSub } from '@/micro/time/date'
import {
  graceDurationToAuth,
  graceDurationToCheckin,
  graceDurationToCheckout,
  graceDurationToCheckoutJustAfterCheckin,
  warningDurationToAuth,
  warningDurationToAuth3
} from '@/cfg'
import { isInGracePeriod, isStartTimeAfterNow } from '@/micro/time/date'
import { isScheduleGreaterThanNow } from '@/micro/time/date'

export function canCheckoutJustAfterCheckin(checkin) {
  return isStartTimeAfterNow(checkin, graceDurationToCheckoutJustAfterCheckin())
}

export function isUnderGracePeriodToCheckout(resource) {
  return isStartTimeAfterNow(resource.schedule_end, graceDurationToCheckout())
}

export function isUnderPeriodToAuth(datetime) {
  return isStartTimeAfterNow(datetime, graceDurationToAuth())
}

export function isUnderPeriodToCheckin(schedule) {
  return isInGracePeriod(
    new Date(),
    schedule,
    graceDurationToCheckin(),
    graceDurationToCheckin()
  )
}

export function isBeforeGracePeriodToCheckin(schedule) {
  return isBeforeNow(schedule, graceDurationToCheckin())
}

export function isItOnTime(item) {
  return isScheduleGreaterThanNow(item.resource.schedule_start)
}

export function wasUnderPeriodToCheckin(effective, schedule) {
  return isInGracePeriod(
    effective,
    schedule,
    graceDurationToCheckin(),
    graceDurationToCheckin()
  )
}

export function wasUnderPeriodToCheckout(effective, schedule) {
  return isInGracePeriod(
    effective,
    schedule,
    graceDurationToCheckout(),
    graceDurationToCheckout()
  )
}

export function wasUnderPeriodToAuth(effective, schedule) {
  return isBeforeSub(effective, schedule, warningDurationToAuth())
}

export function wasUnderPeriodToAuth3(effective, schedule) {
  return isBeforeSub(effective, schedule, warningDurationToAuth3())
}
