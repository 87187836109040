import firebase from 'firebase/app'
// import 'firebase/messaging'
import firebaseConfig from '@/services/firebaseConfig'
// import messagingConfig from '@/services/fbFCMConfig'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import messages from './messages'
// import { sendWebPushToken } from './components/messaging/fbApi'

firebase.initializeApp(firebaseConfig)
// const messaging = firebase.messaging()
// messaging
//   .getToken({ vapidKey: messagingConfig.vapidKey })
//   .then(async currentToken => {
//     if (currentToken) {
//       try {
//         await sendWebPushToken(currentToken)
//       } catch (error) {
//         console.log('The current token cound not be sent it.')
//       }
//     } else {
//       // Show permission request UI
//       console.log(
//         'No registration token available. Request permission to generate one.'
//       )
//       // ...
//     }
//     console.log('messaging/token', currentToken)
//   })
//   .catch(error => console.log('messaging/error', error))

// messaging.onMessage(payload => console.log('onMessage(payload)', payload))

// Consider usign fcm_options.link

const isDebugOn = process.env.NODE_ENV !== 'production' ? true : false
if (isDebugOn) {
  // Storage (Not working)
  // const storage = firebase.storage()
  // storage.useEmulator('localhost', 9199)
  // Functions
  // firebase.functions().useEmulator('localhost', 5001)
  // Auth
  //firebase.auth().useEmulator('http://localhost:9099/')
  // functions.useFunctionsEmulator(isDebugOn);
  // Firestore
  // firebase.firestore().settings({
  //   host: 'localhost:8081',
  //   ssl: false
  // })
}
// import { watchUnauthResponse } from '@/net/axiosRequest'

Vue.use(VueI18n)
Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'es',
  messages
})

new Vue({
  i18n,
  router,
  store,
  vuetify,
  created() {
    // watchUnauthResponse(() => this.$store.dispatch('user/logout'))
  },
  render: h => h(App)
}).$mount('#app')

// export function watchUnauthResponse(reaction) {
//   interceptResponse(
//     response => response,
//     error => {
//       if (error.response.status === 401) {
//         reaction()
//       }
//       return Promise.reject(error)
//     }
//   )
// }
