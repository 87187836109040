const messages = {
  es: {
    general: {
      accept: 'Aceptar',
      add: 'Agregar',
      send: 'Enviar',
      continue: 'Continuar',
      cancel: 'Cancelar',
      next: 'Siguiente',
      back: 'Regresar'
    },
    profile: {
      preTitle: 'Antes de ingresar tus documentos',
      title: 'Necesitas completar tu perfil',
      preTitleFulfilled: 'Tu perfil está completo',
      titleFulfilled: 'Ahora puedes agregar tus documentos',
      btnTitle: 'Agregar tus documentos',
      supervisor: {
        title: 'Has identificado a tu jefe',
        alert: '1. Identificar a tu jefe inmediato',
        email: 'Correo de tu jefe inmediato'
      },
      ou: {
        title: 'Has seleccionado tu organización',
        alert: '2. Seleccionar tu organización'
      },
      roles: {
        title: 'Además desempeñaré los cargos',
        alert: '3. Seleccionar tus roles'
      }
    },
    requirements: {
      preTitle: 'Antes de solicitar acceso',
      title: 'Necesitas anexar tus constancias',
      preTitleFulfilled: 'Tus constancias están completas',
      titleFulfilled: 'Ahora puedes solicitar acceso',
      btnTitle: 'Solicitar acceso',
      documents: {
        alert: 'Agregar las constancias requeridas.',
        title: 'Has agregado las constancias requeridas'
      }
    },
    request: {
      title: 'Es una solicitud de acceso para',
      date: 'Selecciona la fecha',
      time: 'Selecciona el horario',
      motivation: 'Escribe el motivo de la visita'
    },
    site: {
      preTitle:
        'Selecciona si es personal o es una invitación para un proveedor:',
      title: 'Selecciona el sitio'
    },
    date: {
      request: 'Fecha solicitada'
    },
    time: {
      from: 'Hora de entrada',
      to: 'Hora de salida'
    }
  },
  en: {
    general: {
      accept: 'Accept',
      add: 'Add',
      send: 'Send',
      continue: 'Continue',
      cancel: 'Cancel',
      next: 'Next',
      back: 'Back'
    },
    requirements: {
      preTitle: 'Before requesting access',
      preTitleFulfilled: 'Now you can request access',
      title: 'You need to have these requirements',
      titleFulfilled: 'You have all the requirements',
      supervisor: 'Select your supervisor',
      documents: {
        alert: 'You have not yet attached any documents to continue.',
        title: 'Attach the documents required for your access'
      }
    },
    request: {
      title: 'Access request',
      date: 'Requested date',
      time: 'Select check-in and check-out time',
      motivation: 'Write the reason for the visit'
    },
    site: {
      title: 'Select the desired site'
    },
    date: {
      request: 'Requested date'
    },
    time: {
      from: 'From',
      to: 'To'
    }
  }
}

export default messages
