import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)
import colors from 'vuetify/lib/util/colors'
export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        primary: colors.grey.darken3,
        // primary: colors.indigo.darken4,
        secondary: colors.blue.lighten4,
        accent: colors.indigo.base, // #3F51B5
        identity: '#e1000f'
        // error: colors.pink.darken3
      }
    }
  }
})
