import defaultState from '@/store/default/descriptions/auths'
import { authWorkflow } from '@/components/requests/workflowControl'

export const namespaced = true

export const state = {}

export const getters = {
  authTextsById(state) {
    return (id, type) => {
      const workflow = authWorkflow(type)
      return state[workflow].find(description => description.id === id)
    }
  },

  authsNum(state) {
    return type => {
      const workflow = authWorkflow(type)
      return state[workflow].length
    }
  }
}

export const mutations = {
  INIT(state) {
    const { auth, auth_doc } = defaultState
    state['auth'] = auth
    state['auth_doc'] = auth_doc
  }
}

export const actions = {
  setupAuthsWorkflow({ commit }) {
    commit('INIT')
  }
}
