<template lang="html">
  <section id="firebaseui-auth-container"></section>
</template>

<script>
import { mapActions } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
// import { enablePersistence } from '@/fbApi'
//@todo const firebaseResolver = lang => require('@/firebaseui/firebaseui__' + lang)
//@todo const firebaseui = firebaseResolver('es_419')

// import * as firebaseui from 'firebaseui'
import * as firebaseui from 'firebaseui/dist/npm__es_419'
import 'firebaseui/dist/firebaseui.css'

export default {
  created() {
    this.intervalId = null
    this.ui = null
    //@todo send it to the App nop we use a query here!! enablePersistence()
  },
  mounted() {
    const initSession = this.initSession
    const initCompletedSections = this.initCompletedSections
    // const getRelationship = this.getRelationship
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult) {
          // User successfully signed in.
          // Return true if it will make the redirection
          // Return false if it will delegate the routing to the vue router
          const user = authResult.user
          user
            .getIdTokenResult()
            .then(tokenResult => {
              user
                .getIdToken()
                .then(accessToken => {
                  const claims = tokenResult.claims
                  const accountDetails = {
                    displayName: user.displayName,
                    email: user.email,
                    uid: user.uid,
                    accessToken,
                    providerData: user.providerData,
                    appId: 'av',
                    o: claims.o,
                    r: claims.r || [],
                    s: claims.s || [],
                    l: claims.l || '',
                    aid: claims.aid || '',
                    aname: claims.aname || '',
                    areas: claims.areas
                  }
                  console.log('custom claims@Login', claims)
                  initSession(accountDetails)
                  initCompletedSections(user.uid).then(() => {
                    // 2. Prepare the access rules
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(henkel\.com)$/
                    const isTestEmail =
                      [
                        'linebip@gmail.com',
                        'ix.comunes@gmail.com',
                        'ix.juanmanuel@gmail.com',
                        'hi@linebip.com',
                        'diego@linebip.com'
                      ].find(e => e === tokenResult.claims.email) !== undefined
                    const isCorpEmail =
                      pattern.test(tokenResult.claims.email) || isTestEmail

                    const isNewUser = authResult.additionalUserInfo.isNewUser
                    const canAccess =
                      isCorpEmail && tokenResult.claims.email_verified

                    // 3. Init rules-based routing
                    if (isCorpEmail && isNewUser) {
                      user
                        .sendEmailVerification()
                        .then(() => {
                          firebase
                            .auth()
                            .signOut()
                            .then(() => {
                              window.location.assign('/onverification')
                              return true
                            })
                        })
                        .catch(error => {
                          firebase
                            .auth()
                            .signOut()
                            .then(() => {
                              console.log('Can not send it', error)
                            })
                        })
                    } else if (
                      isCorpEmail &&
                      !tokenResult.claims.email_verified
                    ) {
                      // Wait for user verificatioin
                      window.location.assign('/onverification')
                      return true
                    } else if (!canAccess) {
                      // Reject the access without notice
                      window.location.assign('/login')
                      return true
                    } else {
                      // Succesful access then, it delegates to the vue router
                      return false
                    }
                  })
                })
                .catch(error => {
                  console.log('Can not get the access token', error)
                })
            })
            .catch(error => {
              console.log('Can not get the token result', error)
            })
          // It runs synchronous in the mean time &
          // it is waiting for the previous promise
          // to resolve to decide which route will
          // be decided by the vue router.
          return false
        }
      },
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID]
    }

    this.intervalId = setInterval(() => {
      // Wait the DOM and react to page reloads.
      if (firebase.auth() && !firebaseui.auth.AuthUI.getInstance()) {
        this.ui = new firebaseui.auth.AuthUI(firebase.auth())
        this.ui.start('#firebaseui-auth-container', uiConfig)
        clearInterval(this.intervalId)
        this.intervalId = null
      }
    }, 100)
  },
  methods: {
    ...mapActions('user', ['initSession', 'initCompletedSections'])
    // getRelationship() {
    //   const elements = this.$route.name ? this.$route.name.split('/') : []
    //   return (elements.length > 0 && elements[1]) || 'a'
    // }
  }
}
</script>

<style lang="scss">
.firebaseui-link {
  color: #ff1744 !important;
}

.mdl-button {
  border-radius: 20px;
}

.mdl-button--primary.mdl-button--primary {
  color: #ff1744;
}

.mdl-button--raised.mdl-button--colored:active,
.mdl-button--raised.mdl-button--colored:focus:not(:active),
.mdl-button--raised.mdl-button--colored:hover {
  background-color: #ff1744;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: #e1000f;
}

.mdl-button.mdl-button--colored {
  color: #e1000f;
}

.mdl-button--raised.mdl-button--colored {
  background: #e1000f;
  color: #fff;
}

.mdl-shadow--2dp {
  box-shadow: 0 0 0 0 rgb(0 0 0), 0 0 0 0 rgb(0 0 0), 0 0 0 0 rgb(0 0 0);
}

.firebaseui-subtitle,
.firebaseui-title {
  color: #e1000f;
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, sans-serif !important;
}

.firebaseui-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  left: 10px;
  right: 10px;
}
</style>
