import {
  currentStageOf,
  isAuthStage,
  isAuth3Stage,
  isCheckinStage,
  isCheckoutStage,
  isNoShowStage,
  isCanceledStage,
  isPass2Stage,
  isPass1Stage
} from '@/components/requests/stagesControl'
import { isCodeActive } from '@/components/requests/codesControl'
import {
  areAuthsApprovedToCheckin,
  areSomeAuthsRejected,
  currentAuths
} from '@/components/requests/authsControl'
import {
  isBeforeGracePeriodToCheckin,
  isUnderPeriodToCheckin,
  isUnderPeriodToAuth,
  wasUnderPeriodToAuth3
} from '@/components/requests/periodsControl'
import {
  stageStatusWithAlert,
  wasStagePassActive,
  wasStageCheckedOutOnLate,
  wasStageCheckedOutOnTime,
  wasStageCheckedInOnLate,
  wasStageCheckedInOnTime,
  wasAuthRejectedOnLate,
  wasAuth3ApprovedOnLate,
  wasAuthApprovedOnLate,
  wasAuthRejectedOnTime,
  wasAuth3ApprovedOnTime,
  wasAuthApprovedOnTime,
  stageStatus
} from '@/components/requests/statusControl'

/**
 * It returns the stage status
 * @param {Object} stage The required stage
 * @param {Object} item The required item
 * @returns A string representing the status
 */
export function statusOf(stage, item) {
  const isAuth = isAuthStage(stage)
  const isAuth3 = isAuth3Stage(stage)
  if (
    wasAuth3ApprovedOnTime(item, stage, isAuth3) ||
    wasAuthApprovedOnTime(item, stage, isAuth) ||
    wasAuthRejectedOnTime(item, stage, isAuth) ||
    wasStageCheckedInOnTime(item, stage, isAuth) ||
    wasStageCheckedOutOnTime(item, stage, isAuth)
  ) {
    return stageStatus(stage)
  } else if (
    wasAuth3ApprovedOnLate(item, stage, isAuth3) ||
    wasAuthApprovedOnLate(item, stage, isAuth) ||
    wasAuthRejectedOnLate(item, stage, isAuth) ||
    wasStageCheckedInOnLate(item, stage, isAuth) ||
    wasStageCheckedOutOnLate(item, stage, isAuth)
  ) {
    return stageStatusWithAlert(stage)
  } else if (wasStagePassActive(item, stage, isAuth)) {
    return isCodeActive(stage, item) ? 'approved' : 'rejected'
  }
}

/**
 * It return the current workflow status
 * @param {Object} item The required item
 * @param {Object} store A vuex store
 * @returns A string representing the status
 */
export function currentStatus(item, store) {
  // Rejected
  if (areSomeAuthsRejected(currentAuths(item))) return 'rejected'

  const current = currentStageOf(item.workflow.stages)

  // Is next current auth3
  const isNextAuth3 = store.getters['workflows/willNextStageAuth3'](item)
  const onTimeToAuth3 = wasUnderPeriodToAuth3(
    current.effective,
    item.resource.schedule_end
  )
  if (isNextAuth3 && onTimeToAuth3) return 'to_auth'
  else if (isNextAuth3 && !onTimeToAuth3) return 'to_auth_due'

  // Is next current Auth1 & Auth2
  const onTimeToCheckin = isUnderPeriodToCheckin(item.resource.schedule_start)
  const onTimeToAuth = isUnderPeriodToAuth(item.resource.schedule_start)
  const isNextAuth = store.getters['workflows/willNextStageAuth'](item)
  const isCurrentNextAuth = isNextAuth && (onTimeToCheckin || onTimeToAuth)
  if (isCurrentNextAuth) return 'to_auth'

  // Pass 2
  const onBeforeGracePeriodToCheckin = isBeforeGracePeriodToCheckin(
    item.resource.schedule_start
  )
  if (isPass1Stage(current) && onBeforeGracePeriodToCheckin) return 'pass1'

  // To checkin
  const isNextCheckin = store.getters['workflows/willNextStageCheckin'](item)
  const areAuthsToCheckin = areAuthsApprovedToCheckin(item)
  const isReadyToCheckin = isNextCheckin && areAuthsToCheckin && onTimeToCheckin
  if (isReadyToCheckin) return 'to_checkin'

  // No-show
  const isCheckinDue = isNextCheckin && areAuthsToCheckin && !onTimeToCheckin
  const isNoShow = isCheckinDue || isNoShowStage(current)
  if (isNoShow) return 'no_show'

  // Canceled
  const isCurrentCheckin = isCheckinStage(current)
  const isCanceled = isCurrentCheckin && isCanceledStage(current)
  if (isCanceled) return 'canceled'

  // Pass 2
  if (isPass2Stage(current) && onTimeToCheckin) return 'pass2'

  // Checkin
  if (isCurrentCheckin) return 'checkin'

  // Checkout
  if (isCheckoutStage(current)) return 'checkout'

  // Expired
  if (!onTimeToCheckin) return 'expired'

  return 'error'
}

export function isCurrentStatusRejected(item, store) {
  return currentStatus(item, store) === 'rejected'
}
