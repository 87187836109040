import {
  isUnderGracePeriodToCheckout,
  isUnderPeriodToAuth,
  isUnderPeriodToCheckin
} from '@/components/requests/periodsControl'
// import { getJsonItem } from '@/store/nativeStorage'
import { addNewAccessRequest } from './requestsApi/fbWriteRequestsApi'
import { addNewAccessStage } from './requestsApi/fbWriteStagesApi'

export const namespaced = true

// const item = getJsonItem('requests')
const requests = { request: [], auth: [] }
// item && item.requests ? item.requests : { active: [], past: [] }

const Input = {
  init: () => {
    const initInputRequest = {
      site: null,
      date: null,
      fromTime: null,
      toTime: null,
      duration: null,
      motivation: null,
      terms: false,
      party: {
        group: 'employee',
        attrs: null
      }
    }
    const initInputPartyAttrs = {
      alias: null,
      email: null,
      code: '+52',
      phone: null,
      people: 1
    }
    const initInput = initInputRequest
    initInput.party.attrs = initInputPartyAttrs
    return initInput
  },

  initValid: () => {
    return {
      party: false,
      site: false,
      date: false,
      time: false,
      motivation: false,
      terms: false
    }
  },

  reset: state => {
    state.input = null
    state.input = { ...Input.init() }
    state.valid = null
    state.valid = { ...Input.initValid() }
  }
}

export const state = {
  input: { ...Input.init() },
  valid: { ...Input.initValid() },
  requestStep: 1,
  requests,
  loading: false
}

/**
 * Getters
 */
export const getters = {
  requestsByType(state) {
    return type => state.requests[type]
  },

  currentInput(state) {
    return state.input
  },

  currentStep(state) {
    return state.requestStep
  },

  draft(state) {
    return state.input
  },

  party(state) {
    return state.input.party.attrs
  },

  groupName(state) {
    return state.input.party.group
  },

  isValidRequest(state) {
    return Object.values(state.valid).reduce(
      (previous, current) => previous && current,
      true
    )
  },

  isValidSection(state) {
    return section => state.valid[section]
  },

  areRequests(state) {
    if (state.requests) {
      if (state.requests.request) {
        return state.requests.request.length > 0
      } else if (state.requests.auth) {
        return state.requests.request.length > 0
      }
    }
    return false
  }
}

/**
 * Sync mutations
 */
export const mutations = {
  SET_VALID_REQUEST(state, { section, value }) {
    state.valid[section] = value
  },
  SAVE_DRAFT(state, { key, value }) {
    state.input[key] = value
  },
  SAVE_PARTY_DRAFT(state, { key, value }) {
    state.input.party.attrs[key] = value
  },
  SET_GROUP(state, name) {
    state.input.party.group = name
  },
  UPDATE_REQUESTS(state, { type, requests }) {
    state.requests[type] = requests
  },
  SET_STEP(state, step) {
    state.requestStep = step
  },
  INC_STEP(state) {
    state.requestStep = state.requestStep + 1
  },
  DEC_STEP(state) {
    state.requestStep = state.requestStep - 1
  },
  SET_LOADING(state) {
    state.loading = true
  },
  SET_LOADED(state) {
    state.loading = false
  }
}

/**
 * Async actions
 */
export const actions = {
  saveDraft({ commit }, { key, value }) {
    return commit('SAVE_DRAFT', { key, value })
  },

  saveParty({ commit }, { key, value }) {
    return commit('SAVE_PARTY_DRAFT', { key, value })
  },

  selectGroup({ commit }, name) {
    return commit('SET_GROUP', name)
  },

  updateValidSection({ commit }, { section, value }) {
    commit('SET_VALID_REQUEST', { section, value })
  },

  async sendRequestToOrg({ getters, rootGetters }) {
    const rs = rootGetters['user/relationship']
    const ou = rootGetters['user/ou']
    const area = rootGetters['user/area']
    const areaName = rootGetters['user/areaName']
    const uid = rootGetters['user/uid']
    const input = getters.currentInput
    const name = rootGetters['user/name']
    const info = rs === 'a' ? null : input.party.attrs || null
    const party = { name, area, aname: areaName, l: rs, o: ou, info }
    await addNewAccessRequest(uid, input, party, rootGetters)
    Input.reset(state)
  },

  async sendStage({ dispatch }, { item, action, comment }) {
    const customStageAttrs = {}
    const area = 'any'
    return await dispatch('postStageTo', {
      area,
      item,
      action,
      comment,
      customStageAttrs
    })
  },

  async sendCheckStage({ dispatch }, { item, action, comment }) {
    const customStageAttrs = { no_show: false, canceled: false }
    const area = 'any'
    return await dispatch('postStageTo', {
      area,
      item,
      action,
      comment,
      customStageAttrs
    })
  },

  async postStageTo(
    { dispatch, rootGetters },
    { area, item, action, comment, customStageAttrs }
  ) {
    const relationship = rootGetters['user/relationship']
    const ou = rootGetters['user/ou']
    const uid = rootGetters['user/uid']
    const name = rootGetters['user/name']

    const currentNextStageDescription =
      rootGetters['workflows/currentNextStageDescription']
    const next = currentNextStageDescription(item)
    const isNextAuth = next.type === 'auth'
    const status = action === 'approve' ? 'approved' : 'rejected'
    const alert = await dispatch('shouldAlert', { item, next })
    const defaultNext = next.next
    const stage = {
      position: next.position,
      id: next.id,
      type: next.type,
      auth: isNextAuth,
      role: next.role,
      next: defaultNext,
      description: next.description,
      status,
      alert,
      uid,
      name,
      comment,
      ...customStageAttrs
    }
    return await addNewAccessStage(
      relationship,
      ou,
      area,
      item,
      stage,
      rootGetters
    )
  },

  shouldAlert({ rootGetters }, { item, next }) {
    const willNextStageAuth = rootGetters['workflows/willNextStageAuth']
    const willNextStageCheckin = rootGetters['workflows/willNextStageCheckin']
    const willNextStageCheckout = rootGetters['workflows/willNextStageCheckout']

    const isNextAuth = next.type === 'auth'
    if (isNextAuth) {
      return willNextStageAuth(item)
        ? !isUnderPeriodToAuth(item.resource.schedule_start)
        : !isUnderPeriodToCheckin(item.resource.schedule_start)
    } else if (willNextStageCheckin(item)) {
      return !isUnderPeriodToCheckin(item.resource.schedule_start)
    } else if (willNextStageCheckout(item)) {
      return !isUnderGracePeriodToCheckout(item.resource)
    }
  },

  updateStep({ commit }, step) {
    commit('SET_STEP', step)
  },

  incStep({ commit }) {
    commit('INC_STEP')
  },

  decStep({ commit }) {
    commit('DEC_STEP')
  },

  resetStep({ commit }) {
    commit('SET_STEP', 1)
  },

  updateRequests({ commit }, { type, requests }) {
    commit('UPDATE_REQUESTS', { type, requests })
  },

  setLoading({ commit }) {
    commit('SET_LOADING')
  },

  setLoaded({ commit }) {
    commit('SET_LOADED')
  }
}
