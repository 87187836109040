import { isAuthStage } from './stagesControl'

export function hasRequestPendingAuthsOf(stages, store, type) {
  return (
    areAllAuthsPendingOf(stages) || areSomeAuthsPendingOf(stages, store, type)
  )
}

function areAllAuthsPendingOf(stages) {
  return currentAuthsOf(stages).length === 0
}

function areSomeAuthsPendingOf(stages, store, type) {
  return (
    currentAuthsOf(stages).length <
    store.getters['authsWorkflow/authsNum'](type)
  )
}

export function currentAuths(item) {
  return currentAuthsOf(item.workflow.stages)
}

export function currentAuthsOf(stages) {
  return stages.filter(stage => isAuthStage(stage))
}

export function areSomeAuthsRejected(authsStages) {
  return !authsStages.reduce((result, stage) => {
    return result && stage.status === 'approved'
  }, true)
}

export function areAllAuthsApproved(item) {
  return (
    isHeadAuthApproved(item.workflow.stages) &&
    isHeadSecurityAuthApproved(item.workflow.stages) &&
    isHealthAuthApproved(item.workflow.stages)
  )
}

export function areAuthsApprovedToCheckin(item) {
  return (
    isHeadAuthApproved(item.workflow.stages) &&
    isHeadSecurityAuthApproved(item.workflow.stages)
  )
}

export function isHeadAuthApproved(stages) {
  return (
    stages.find(stage => stage.role === 'head' && stage.auth === true) !==
    undefined
  )
}

export function isHeadSecurityAuthApproved(stages) {
  return (
    stages.find(
      stage => stage.role === 'head_security' && stage.auth === true
    ) !== undefined
  )
}

export function isHealthAuthApproved(stages) {
  return (
    stages.find(stage => stage.role === 'health' && stage.auth === true) !==
    undefined
  )
}
