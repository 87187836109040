import { graceDurationToCheckin, warningDurationToAuth3 } from '@/cfg'
import { isStartTimeAfterNow } from '@/micro/time/date'

export function isCodeActive(stage, item) {
  if (stage.id === 'pass1') {
    return (
      item.workflow.stages.find(stage => stage.id === 'pass2') === undefined &&
      isUnderGracePeriodToAuth3(item.resource)
    )
  } else if (stage.id === 'pass2') {
    return (
      item.workflow.stages.find(stage => stage.id === 'pass2') !== undefined &&
      isUnderGracePeriodToCheckin(item.resource)
    )
  }
}

function isUnderGracePeriodToCheckin(resource) {
  return isStartTimeAfterNow(resource.schedule_start, graceDurationToCheckin())
}

function isUnderGracePeriodToAuth3(resource) {
  return isStartTimeAfterNow(resource.schedule_start, warningDurationToAuth3())
}
