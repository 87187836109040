<template>
  <v-app>
    <!-- Drawer Profile -->
    <!-- <v-navigation-drawer absolute right temporary v-model="drawer">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon>mdi-account</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ diplayName }}</v-list-item-title>
            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense rounded>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          @click="signOut"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->
    <!-- /Drawer Profile -->
    <!-- Breadcrumb -->
    <v-app-bar app :dark="isDark" dense v-if="isLoggedIn">
      <v-breadcrumbs v-if="!isSomeCheck" :items="routes">
        <v-breadcrumbs-item
          class="theme--av"
          ripple
          exact-active-class="exactActiveCls"
          exact
          slot="item"
          slot-scope="{ item }"
          :disabled="item.disabled"
          :to="item.to"
          :class="conditionCls"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <v-spacer />
      <!-- <v-btn icon @click.stop="drawer = !drawer">
        <v-icon>mdi-account</v-icon>
      </v-btn> -->
      <v-btn icon @click="signOut">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- /Breadcrumb -->
    <v-main :class="bgColor">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
export default {
  data: () => ({
    drawer: false,
    group: null,
    items: [{ title: 'Salir', icon: 'mdi-logout', link: '/' }]
  }),
  computed: {
    ...mapGetters('user', [
      'user',
      'isLoggedIn',
      'isAppInit',
      'amIAttendantSecurity'
    ]),
    ...mapGetters('routes', ['routes']),
    isDark() {
      return this.isLoggedIn && this.amIAttendantSecurity
    },
    bgColor() {
      return this.isDark ? 'bg-dark' : ''
    },
    isSomeCheck() {
      // Checkin / Checkout
      return this.$route.name === 'PassCode'
    },
    conditionCls() {
      return 'enabledLink'
    },
    diplayName() {
      return this.user ? this.user.displayName : ''
    },
    email() {
      return this.user ? this.user.email : ''
    },
    providerId() {
      return this.user ? this.user.providerData[0].providerId : ''
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
    isAppInit(newVal) {
      if (newVal) {
        if (this.amIAttendantSecurity) {
          if (this.$route.path != '/check') {
            this.$router.push({ name: 'PassCode' })
          }
        } else {
          if (this.$route.path != '/requests') {
            this.$router.push({ name: 'Requests' })
          }
        }
      }
    }
    // areSectionsRequiredCompleted: {
    //   deep: true,
    //   immediate: true,
    //   handler(completed) {
    //     console.log('areSectionsCompleted before')
    //     if (completed) {
    //       console.log('areSectionsCompleted on true')
    //       this.$router.push({ name: 'Requests' })
    //     }
    //   }
    // }
  },
  methods: {
    ...mapActions('user', ['logout', 'areSectionsRequiredCompleted']),
    ...mapActions('workflows', ['setupWorkflows']),
    ...mapActions('assignments', ['setupAssignments']),
    ...mapActions('authsWorkflow', ['setupAuthsWorkflow']),
    ...mapActions('sites', ['setupSites']),
    ...mapActions('routes', ['setupRoutes']),
    async signOut(evt) {
      // It is a sign up from a valid interior view
      evt.stopPropagation()
      await firebase.auth().signOut()
      this.logout()
      window.location.assign('/login')
      // this.$router.push({ name: 'Login' })
    }
  },
  created() {
    if (
      firebase.auth() &&
      this.$route.name &&
      this.$route.name !== 'PassCode'
    ) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          console.log('Successfuly logout when App is created')
          this.logout()
        })
    }

    this.setupWorkflows()
    this.setupAssignments()
    this.setupAuthsWorkflow()
    this.setupSites()
    this.setupRoutes()
  },

  beforeDestroy() {
    this.$store.dispatch('requests/unsubscribe')
  }
}
</script>

<style lang="scss">
.v-breadcrumbs__divider {
  padding: 0 2px !important;
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  // border-bottom: 1px solid red !important;
  -webkit-box-shadow: 0 1px 0 0 rgb(0 0 0 / 10%) !important;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 10%) !important;
}
.v-breadcrumbs__item {
  font-size: 16px;
}
.enabledLink a.v-breadcrumbs__item {
  // enabled links
  color: #111 !important;
}
a.v-breadcrumbs__item.v-breadcrumbs__item--disabled {
  // disabled links
  color: #999 !important;
}
a.exactActiveCls.v-breadcrumbs__item--disabled.v-breadcrumbs__item {
  // active link
  color: #e1000f !important;
}
.bg-dark {
  background-color: #000;
}
.theme--av .v-breadcrumbs__item {
  text-transform: lowercase;
}
</style>
