import defaultState from '@/store/default/descriptions/assignments'
import { stageRoleId } from '@/components/requests/stagesControl'

export const namespaced = true

export const state = {
  assignmentsDescriptions: []
}

export const getters = {
  assignmentDescription(state) {
    return roleId => {
      return state.assignmentsDescriptions.find(
        description => description.id === roleId
      )
    }
  },

  assignmentDescriptionByRoleId(state, getters) {
    return roleId => getters.assignmentDescription(roleId)
  },

  areaByRoleId(state, getters) {
    return roleId => getters.assignmentDescriptionByRoleId(roleId).area_name
  },

  areaInStage(state, getters) {
    return stage =>
      getters.assignmentDescriptionByRoleId(stageRoleId(stage)).area_name
  },

  roleInStage(state, getters) {
    return stage => getters.assignmentDescriptionByRoleId(stage).role_name
  }
}

export const mutations = {
  INIT(state) {
    state.assignmentsDescriptions = defaultState
  }
}

export const actions = {
  setupAssignments({ commit }) {
    commit('INIT')
  }
}
