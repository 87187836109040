import Vue from 'vue'
import Vuex from 'vuex'
import {
  saveUserPlugin,
  saveResourcesPlugin,
  saveRequirementsPlugin,
  saveRequestsPlugin
} from '@/plugins/store'

import * as user from '@/store/modules/user.js'
import * as profile from '@/store/modules/profile.js'
import * as routes from '@/store/modules/routes.js'
import * as resources from '@/store/modules/resources.js'
import * as requirements from '@/store/modules/requirements.js'
import * as requests from '@/store/modules/requests.js'
import * as sites from '@/store/modules/sites.js'
import * as assignments from '@/store/modules/assignments.js'
import * as authsWorkflow from '@/store/modules/authsWorkflow.js'
import * as workflows from '@/store/modules/workflows.js'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    saveUserPlugin,
    saveResourcesPlugin,
    saveRequirementsPlugin,
    saveRequestsPlugin
  ],
  modules: {
    user,
    profile,
    routes,
    resources,
    requirements,
    requests,

    sites,
    assignments,
    authsWorkflow,
    workflows
  }
})
