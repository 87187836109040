import { getJsonItem } from '@/store/nativeStorage'

export const namespaced = true

const item = getJsonItem('profile')
const profile = item && item.profile ? item.profile : { active: [], past: [] }

const Input = {
  init: () => {
    const initInputRequest = {
      email: null,
      ou: null,
      roles: []
    }
    const initInput = initInputRequest
    return initInput
  },
  initValidSection: () => {
    return {
      supervisor: false,
      ou: false,
      roles: true
    }
  },
  reset: state => {
    state.input = null
    state.input = { ...Input.init() }
    state.valid = null
    state.valid = { ...Input.initValidSection() }
  }
}

export const state = {
  input: { ...Input.init() },
  valid: { ...Input.initValidSection() },
  profile,
  overlay: false
}

export const getters = {
  isVisibleOverlay(state) {
    return state.overlay
  },
  draft(state) {
    return state.input
  },
  isValidProfileRequest(state) {
    return Object.values(state.valid).reduce(
      (previous, current) => previous && current,
      true
    )
  },
  isValidSection(state) {
    return section => state.valid[section]
  },
  hasProfile(state) {
    return (
      !!state.profile &&
      (state.profile.past.length > 0 || state.profile.active.length > 0)
    )
  },
  isFilled(state) {
    return key => (state.input[key] ? state.input[key].length > 0 : false)
  }
}

export const mutations = {
  SET_VALID_REQUEST(state, { section, value }) {
    state.valid[section] = value
  },
  SAVE_DRAFT(state, { key, value }) {
    state.input[key] = value
  },
  SHOW_OVERLAY(state) {
    state.overlay = true
  },
  HIDE_OVERLAY(state) {
    state.overlay = false
  },
  SEND(state) {
    Input.reset(state)
  }
}

export const actions = {
  saveDraft({ commit }, { key, value }) {
    return commit('SAVE_DRAFT', { key, value })
  },
  updateValidSection({ commit }, { section, value }) {
    commit('SET_VALID_REQUEST', { section, value })
  },
  send({ commit }) {
    commit('SEND')
  },
  showOverlay({ commit }) {
    commit('SHOW_OVERLAY')
  },
  hideOverlay({ commit }) {
    commit('HIDE_OVERLAY')
  }
}
