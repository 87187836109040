import {
  currentStageOf,
  isCheckinStage,
  isCheckoutStage,
  isNoShowStage,
  isCanceledStage,
  isPass2Stage,
  isPass1Stage
} from '@/components/requests/stagesControl'
import {
  areAuthsApprovedToCheckin,
  areSomeAuthsRejected,
  currentAuths
} from '@/components/requests/authsControl'
import {
  isBeforeGracePeriodToCheckin,
  isUnderPeriodToCheckin,
  wasUnderPeriodToAuth3
} from '@/components/requests/periodsControl'

export { statusOf } from '@/components/requests/employees/oneAccessStatus'

/**
 * It return the current workflow status
 * @param {Object} item The required item
 * @param {Object} store A vuex store
 * @returns A string representing the status
 */
export function currentStatus(item, store) {
  // Rejected
  if (areSomeAuthsRejected(currentAuths(item))) return 'rejected'

  const current = currentStageOf(item.workflow.stages)

  // Is next current auth3
  const isNextAuth3 = store.getters['workflows/willNextStageAuth3'](item)
  const onTimeToAuth3 = wasUnderPeriodToAuth3(
    current.effective,
    item.resource.schedule_end
  )
  if (isNextAuth3 && onTimeToAuth3) return 'to_auth'
  else if (isNextAuth3 && !onTimeToAuth3) return 'to_auth_due'

  // Is next current Auth1 & Auth2
  const onTimeToCheckin = isUnderPeriodToCheckin(item.resource.schedule_start)
  const isNextAuth = store.getters['workflows/willNextStageAuth'](item)
  const isCurrentNextAuth = isNextAuth && onTimeToCheckin
  if (isCurrentNextAuth) return 'to_auth'

  // Pass 2
  const onBeforeGracePeriodToCheckin = isBeforeGracePeriodToCheckin(
    item.resource.schedule_start
  )
  if (isPass1Stage(current) && onBeforeGracePeriodToCheckin) return 'pass1'

  // To checkin
  const isNextCheckin = store.getters['workflows/willNextStageCheckin'](item)
  const areAuthsToCheckin = areAuthsApprovedToCheckin(item)
  const isReadyToCheckin = isNextCheckin && areAuthsToCheckin && onTimeToCheckin
  if (isReadyToCheckin) return 'to_checkin'

  // No-show
  const isCheckinDue = isNextCheckin && areAuthsToCheckin && !onTimeToCheckin
  const isNoShow = isCheckinDue || isNoShowStage(current)
  if (isNoShow) return 'no_show'

  // Canceled
  const isCurrentCheckin = isCheckinStage(current)
  const isCanceled = isCurrentCheckin && isCanceledStage(current)
  if (isCanceled) return 'canceled'

  // Pass 2
  if (isPass2Stage(current) && onTimeToCheckin) return 'pass2'

  // Checkin
  if (isCurrentCheckin) return 'checkin'

  // Checkout
  if (isCheckoutStage(current)) return 'checkout'

  // Expired
  if (!onTimeToCheckin) return 'expired'

  return 'error'
}

export function isCurrentStatusRejected(item, store) {
  return currentStatus(item, store) === 'rejected'
}
