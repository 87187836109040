import {
  isCheckinStage,
  isCheckoutStage,
  isPassStage
} from '@/components/requests/stagesControl'

import {
  isUnderPeriodToAuth,
  wasUnderPeriodToAuth,
  wasUnderPeriodToAuth3,
  wasUnderPeriodToCheckin,
  wasUnderPeriodToCheckout
} from './periodsControl'

/**
 * It returns the next stage status
 * @param {Object} stage The required stage
 * @param {Object} item The required item
 * @returns A string representing the status
 */
export function nextStatusOf(stage, item) {
  return stage.alert === true ||
    !isUnderPeriodToAuth(item.resource.schedule_start)
    ? 'pending_alert'
    : 'pending'
}

export function stageStatus(stage) {
  return stage.status
}

export const wasAuthApprovedOnTime = (item, stage, isAuth) => {
  return (
    isAuth &&
    wasUnderPeriodToAuth(stage.effective, item.resource.schedule_start) &&
    isAuthSuccessful(stage)
  )
}

export const wasAuth3ApprovedOnTime = (item, stage, isAuth) => {
  return (
    isAuth &&
    wasUnderPeriodToAuth3(stage.effective, item.resource.schedule_end) &&
    isAuthSuccessful(stage)
  )
}

export const wasAuthRejectedOnTime = (item, stage, isAuth) => {
  return (
    isAuth &&
    wasUnderPeriodToAuth(stage.effective, item.resource.schedule_start) &&
    !isAuthSuccessful(stage)
  )
}

export const wasAuthApprovedOnLate = (item, stage, isAuth) => {
  return (
    isAuth &&
    !wasUnderPeriodToAuth(stage.effective, item.resource.schedule_start) &&
    isAuthSuccessful(stage)
  )
}

export const wasAuth3ApprovedOnLate = (item, stage, isAuth) => {
  return (
    isAuth &&
    !wasUnderPeriodToAuth3(stage.effective, item.resource.schedule_end) &&
    isAuthSuccessful(stage)
  )
}

export const wasAuthRejectedOnLate = (item, stage, isAuth) => {
  return (
    isAuth &&
    !wasUnderPeriodToAuth(stage.effective, item.resource.schedule_start) &&
    !isAuthSuccessful(stage)
  )
}

export const wasStageCheckedInOnTime = (item, stage, isAuth) => {
  return (
    !isAuth &&
    isCheckinStage(stage) &&
    wasUnderPeriodToCheckin(stage.effective, item.resource.schedule_start)
  )
}

export const wasStageCheckedInOnLate = (item, stage, isAuth) => {
  return (
    !isAuth &&
    isCheckinStage(stage) &&
    !wasUnderPeriodToCheckin(stage.effective, item.resource.schedule_start)
  )
}

export const wasStageCheckedOutOnTime = (item, stage, isAuth) => {
  return (
    !isAuth &&
    isCheckoutStage(stage) &&
    wasUnderPeriodToCheckout(stage.effective, item.resource.schedule_end)
  )
}

export const wasStageCheckedOutOnLate = (item, stage, isAuth) => {
  return (
    !isAuth &&
    isCheckoutStage(stage) &&
    !wasUnderPeriodToCheckout(stage.effective, item.resource.schedule_end)
  )
}

export function isAuthSuccessful(stage) {
  return isStageApproved(stage)
}

export function isStageApproved(stage) {
  return stage.status === 'approved'
}

export function stageStatusWithAlert(stage) {
  return `${stage.status}_alert`
}

export function wasStagePassActive(item, stage, isAuth) {
  return !isAuth && isPassStage(stage)
}

export function isNextStagePass1(stage) {
  return stage.next === 'pass1'
}

export function isNextStagePass2(stage) {
  return stage.next === 'pass2'
}

export function isNextStagePass(stage) {
  return isNextStagePass1(stage) || isNextStagePass2(stage)
}

import { isCurrentStatusRejected as employeeOneIsCurrentStatusRejected } from '@/components/requests/employees/oneAccessStatus'
import { isCurrentStatusRejected as employeeContinuosIsCurrentStatusRejected } from '@/components/requests/employees/continuosAccessStatus'
import { isCurrentStatusRejected as employeeFilesIsCurrentStatusRejected } from '@/components/requests/employees/accessDocsStatus'
export function isCurrentStatusRejectedOf(item) {
  if (item.workflow.id === 'eoa') return employeeOneIsCurrentStatusRejected
  if (item.workflow.id === 'eca')
    return employeeContinuosIsCurrentStatusRejected
  if (item.workflow.id === 'efa') return employeeFilesIsCurrentStatusRejected
}

import { currentStatus as employeeOneCurrentStatus } from '@/components/requests/employees/oneAccessStatus'
import { currentStatus as employeeContinuosCurrentStatus } from '@/components/requests/employees/continuosAccessStatus'
import { currentStatus as employeeFilesCurrentStatus } from '@/components/requests/employees/accessDocsStatus'
export function currentStatusOf(item) {
  if (item.workflow.id === 'eoa') return employeeOneCurrentStatus
  if (item.workflow.id === 'eca') return employeeContinuosCurrentStatus
  if (item.workflow.id === 'efa') return employeeFilesCurrentStatus
}

import { statusOf as employeeOneStatusOf } from '@/components/requests/employees/oneAccessStatus'
import { statusOf as employeeContinuosStatusOf } from '@/components/requests/employees/continuosAccessStatus'
import { statusOf as employeeFilesStatusOf } from '@/components/requests/employees/accessDocsStatus'
export function statusOf(item) {
  if (item.workflow.id === 'eoa') return employeeOneStatusOf
  if (item.workflow.id === 'eca') return employeeContinuosStatusOf
  if (item.workflow.id === 'efa') return employeeFilesStatusOf
}
