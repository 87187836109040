import firebase from 'firebase/app'
import 'firebase/firestore'
import { isEmployee, isProvider } from '@/workflows/assignmentsConditions'
import { docsApproved } from '@/workflows/docsConditions'
import { projectOpen } from '@/workflows/providersConditions'
import {
  healthApprovalInDuration,
  healthApprovalNotRequired
} from '@/workflows/healthConditions'
import {
  checkinAheadSchedule,
  checkinOnSchedule,
  checkinBehindSchedule,
  checkoutAheadSchedule,
  checkoutOnSchedule,
  checkoutBehindSchedule,
  checkoutException
} from '@/workflows/checksConditions'
import {
  getPolicyDocPath,
  getUserPolicyPath
} from '@/store/modules/conditionsApi/fbReadPolicies'

export const conditionsDic = [
  // Preconditions
  { name: 'isEmployee', fn: isEmployee, async: false },
  { name: 'isProvider', fn: isProvider, async: false },
  { name: 'docsApproved', fn: docsApproved, async: false },

  // Providers
  { name: 'projectOpen', fn: projectOpen, async: true },

  // Health
  {
    name: 'healthApprovalInDuration',
    fn: healthApprovalInDuration,
    async: true
  },
  {
    name: 'healthApprovalNotRequired',
    fn: healthApprovalNotRequired,
    async: true
  },

  // Checkin
  {
    name: 'checkinAheadSchedule',
    fn: checkinAheadSchedule,
    async: false
  },
  {
    name: 'checkinOnSchedule',
    fn: checkinOnSchedule,
    async: false
  },
  {
    name: 'checkinBehindSchedule',
    fn: checkinBehindSchedule,
    async: false
  },

  // Checkout
  {
    name: 'checkoutAheadSchedule',
    fn: checkoutAheadSchedule,
    async: false
  },
  {
    name: 'checkoutOnSchedule',
    fn: checkoutOnSchedule,
    async: false
  },
  {
    name: 'checkoutBehindSchedule',
    fn: checkoutBehindSchedule,
    async: false
  },
  {
    name: 'checkoutException',
    fn: checkoutException,
    async: false
  }
]

/**
 * Evaluates and resolves a codintion as true or false
 * @param {Function} conditionId the condition expressed as Fn
 * @param {Object} context { stage: object, request: object, rootGetters: vuex }
 * @returns Boolean
 * @throws
 */
export async function conditionAssertion(conditionName, context) {
  const matched = conditionsDic.find(
    condition => condition.name === conditionName
  )
  if (matched === undefined) {
    throw new Error(`The condition {${conditionName}} does not exists`)
  } else if (matched.async) {
    return await matched.fn(context)
  } else {
    return matched.fn(context)
  }
}

export const generalPolicyByTopic = async (topic, rootGetters) => {
  const context = rootGetters['user/context']
  const docPath = `${getPolicyDocPath(context)}`
  const policyRef = firebase.firestore().doc(docPath)
  return (await policyRef.get()).get(topic)
}

export const userPolicyCondition = async (
  condition,
  topic,
  request,
  rootGetters
) => {
  const context = rootGetters['user/context']
  const path = getUserPolicyPath(request.uid, topic, context)
  const query = firebase
    .firestore()
    .collection(path)
    .where(condition, '==', true)
  return await query.get()
}
