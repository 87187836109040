import { resolvedNextStageIdById } from '@/workflows/readConditions'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { getControlPath } from './apiControl'

/**
 * Add new access stage
 */
export async function addNewAccessStage(
  rs,
  ou,
  aid,
  request,
  stage,
  rootGetters
) {
  const path = getControlPath(rs, ou, aid, 'covid19pass', 'active')
  return await saveNextStageDoc(path, request, stage, rootGetters)
}

async function saveNextStageDoc(path, request, stage, rootGetters) {
  const ref = stageRef(path, request.id)
  const data = await nextStageDoc(stage, request, rootGetters)
  return await ref.set({ ...data })
}

function stageRef(path, reqId) {
  return firebase
    .firestore()
    .doc(`${path}/${reqId}`)
    .collection('workflow')
    .doc()
}

/**
 * The final document to be saved
 * @param {Object} stage The prepared next stage
 * @param {Object} request The request of the next stage
 * @param {Vuex}  rootGetters
 * @returns The document to be saved
 */
async function nextStageDoc(stage, request, rootGetters) {
  const nextId = await resolvedNextStageIdById(stage.id, request, rootGetters)
  return {
    ...{ ...stage, next: nextId },
    effective: firebase.firestore.Timestamp.fromDate(new Date())
  }
}
