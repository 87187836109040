export default [
  {
    text: 'Perfil',
    disabled: false,
    to: 'Profile'
  },
  {
    text: 'Documentos',
    disabled: true,
    to: 'Documents'
  },
  {
    text: 'Solicitudes',
    disabled: true,
    to: 'Requests'
  }
]
