export function warningDurationToAuth3() {
  return CONSTS.WarningDurationToAuth3InHours
}

export function warningDurationToAuth() {
  return CONSTS.WarningDurationToAuth3InHours
}

export function graceDurationToAuth() {
  return CONSTS.GraceDurationToAuth
}

export function graceDurationToCheckin() {
  return CONSTS.GraceDurationToCheckinInHours
}

export function graceDurationToCheckout() {
  return CONSTS.GraceDurationToCheckoutInHours
}

export function graceDurationToCheckoutJustAfterCheckin() {
  return CONSTS.GraceDurationToCheckoutJustAfterCheckin
}

export const CODE_LENGTH = 6

const CONSTS = {
  WarningDurationToAuthInHours: 12,
  WarningDurationToAuth3InHours: 1,
  GraceDurationToAuth: 1,
  GraceDurationToCheckinInHours: 1,
  GraceDurationToCheckoutInHours: 1,
  GraceDurationToCheckoutJustAfterCheckin: 1 / 6
}
