import firebase from 'firebase/app'
import 'firebase/firestore'
import { generalPolicyByTopic, userPolicyCondition } from './conditions'

export const healthApprovalInDuration = async ({ request, rootGetters }) => {
  const snapshot = await userPolicyCondition(
    'approved',
    'health',
    request,
    rootGetters
  )
  if (snapshot.empty) {
    return false
  } else {
    const doc = snapshot.docs[0]
    const now = firebase.firestore.Timestamp.now().valueOf()
    const start = doc.get('start_date').valueOf()
    const end = doc.get('end_date').valueOf()
    return now >= start && now <= end
  }
}

export const healthApprovalNotRequired = async ({ rootGetters }) => {
  const policy = await generalPolicyByTopic('docs', rootGetters)
  return policy.exists ? policy.required : false
}
